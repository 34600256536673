$(document).ready(function() {
    const loadImages = (entries, observer) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                $(entry).each((i, ent)=>{
                    $(ent.target).find('img.lazyload').each((j, img)=>{
                        let $img = $(img);
                        $img.removeClass('lazyload')
                        $img.attr('src', $img.data().source)
                        observer.unobserve(img)
                    })
                })
            }
        });
    };

    const observer = new IntersectionObserver(loadImages, {
        root: null,    // Use the viewport as the root
        rootMargin: '0px',
        threshold: 0.01 // Load when 10% of the section is visible
    });
    $("section.section").each((i, section)=>{
        observer.observe(section);
    })
});
