frappe.ready(function () {
    const popups = [["Demo", "rfd", '.hero-buttons .btn.btn-dark, #intro .btn-primary'], ["Quotation", "rfq", '.hero-buttons .btn.btn-light, #intro .btn-primary-light']]
    popups.forEach(item=>{
        if(!(item[0] in window)) {
            window[item[1]] = new frappe.ui.Dialog({minimizable: true, title: __(`Request for ${ item[0] }`), size: "extra-large", on_page_show: ()=>{
                window[item[1]].set_message(__("Loading.."))
            }});
            window[item[1]].$message.addClass('d-flex justify-content-center')
            const iFrame = $(`<iframe src="/${item[1]}" style="border: none; width: 100%; height: inherit;"></iframe>`)
            window[item[1]].$body.html(iFrame)
            iFrame.css('min-height', `${$(window).height()-170}px`)
            // Show the loading message when the iframe starts loading
            iFrame.on('loadstart', () => {
                iFrame.addClass('hide');
            });
    
            // Hide the loading message and show the iframe when it finishes loading
            iFrame.on('load', () => {
                window[item[1]].clear_message()
                iFrame.removeClass('hide');
            });
    
            // For older browsers that may not support 'loadstart' event
            iFrame.onload = function () {
                window[item[1]].clear_message()
                iFrame.removeClass('hide');
            };
        }
        $(item[2]).on('click', ()=>{
            window[item[1]].show()
        })
    })
})
